<div class="content-main" class="smooth-animation" #lead>
  <app-header [lead]="lead"></app-header>
  <div class="sidebar">
    <app-sidebar ></app-sidebar>
  </div>
  <div class="content-area">
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</div>
